const navBtn = document.getElementById("nav-btn");
const links = document.getElementById("links");
const down_btn = document.getElementById("down-btn");
const get_app_btns = document.querySelectorAll("#get-app");
const year = document.getElementById("year");

const accordions = document.getElementsByClassName("accordion");

const logo_cover = document.getElementById("logo-cover");

navBtn.addEventListener("click", () => {
  links.classList.toggle("hide");
  down_btn.classList.toggle("hide");
});

year.textContent = new Date().getFullYear();

Array.from(accordions).forEach((accordion) => {
  accordion.addEventListener("click", function () {
    this.classList.toggle("active");

    // Toggle the panel visibility
    const panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
      this.querySelector("svg").classList.remove("rotate");
    } else {
      panel.style.display = "block";
      this.querySelector("svg").classList.add("rotate");
    }
  });
});

logo_cover.addEventListener("click", () => {
  window.location.replace("./index.html");
});

window.addEventListener("DOMContentLoaded", function () {
  const url = new URL(window.location.href);
  const targetId = url.hash;

  if (targetId) {
    const targetElement = document.querySelector(targetId);
    targetElement.scrollIntoView({
      behavior: "smooth",
    });

    var scrollOffset = -10;
    window.scrollBy(0, -scrollOffset);
  }
});

get_app_btns.forEach((app_btn) => {
  app_btn.addEventListener("click", download);
});

down_btn.addEventListener("click", download);

function download() {
  if (isIOS()) {
    window.location.href = "https://apps.apple.com/us/app/postam/id6450558847";
  } else if (isAndroid()) {
    window.location.href =
      "https://play.google.com/store/apps/details?id=io.postam.postam";
  } else {
    window.location.href = "";
  }
}

function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

function isAndroid() {
  return /Android/.test(navigator.userAgent);
}
